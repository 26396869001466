import React from "react";
import "./menu-stylesheet.css";
import {Link} from "react-router-dom";

function Menu(){
return(
<>
<div className="Create-Container">
        <h2 className="Create-Header">What do you want to Create?</h2>
    <div className="Create-Button-Container">
      <Link to='/add/project'><button className="Create-Button Project">New Project</button></Link>
      <Link to='/add/post'><button className="Create-Button Post">New Post</button></Link>    
    </div>
</div>
</> 
)
};

export default Menu;