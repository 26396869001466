import React, { useState } from 'react';
import {BrowserRouter,Routes, Route ,Navigate} from "react-router-dom";
import Login from "./components/login/login";
import axios from "axios";
import Home from "./components/editor/editor";
import Post from "./components/post/post";
import Menu from './components/menu/menu';

function App() {
  
  let apiUrl = process.env.REACT_APP_API_URL;
  let [isLoggedIn,setIsLoggedIn] = useState(false);
  let [errorMessage,setErrorMessage] = useState("");
  let [pData,setPData] = useState([]);
  let [bData,setBData] = useState([]);

function successLogin(){
  setIsLoggedIn(true)
}
function errorLogin(){
  setErrorMessage("Login failed wrong credentials"); 
  setIsLoggedIn(false); 
}
  function logout(){
   setIsLoggedIn(false);
  }
  async function getPost(event){
      await axios.post(apiUrl+"/post/id",{data:event}).then((response)=>{
      setBData(response.data);
      console.log(response);
    }); 
  }
 async function getProject(event){
    await axios.post(apiUrl+"/projects/id",{data:event}).then((response)=>{
      setPData(response.data);
      console.log(response);
    })
  }
  function clearData(){
    setPData([]);
    setBData([]);
    console.log(bData);
    console.log(pData);
  }
  async function savePost(data){
    await axios.post(apiUrl+"/update/post",{data:data}).then((response)=>{
      console.log(response);
    })
  }
  async function saveProject(data){
    await axios.post(apiUrl+"/update/project",{data:data}).then((response)=>{
      console.log(response);
    })
  }
  async function newPost(data){
    await axios.post(apiUrl+"/new/post",{data:data}).then((response)=>{
      console.log(response);
    })
  }
  async function newProject(data){
    await axios.post(apiUrl+"/new/project",{data:data}).then((response)=>{
      console.log(response);
    })
  }
  async function deleteProject(data){
    try{
      await axios.post(apiUrl+"/delete/project",{data:data});
    }catch(err){
      console.log(err);
    }
  }
  async function deletePost(data){
    try{
      await axios.post(apiUrl+"/delete/post",{data:data});
    }catch(err){
      console.log(err);
    }
  }
  return (
  <BrowserRouter>
    <Routes>
      <Route exact path='/' element={isLoggedIn? <Navigate to="/home"/> : <Login errorLogin={errorLogin} successLogin={successLogin} error={errorMessage}/>}/>
      <Route path='/post' element={<Post buttonOnClick={savePost} button='Save changes' postHeader="Posts" data={bData} />}/>
      <Route path='/home' element={<Home deleteProject={deleteProject} deletePost={deletePost} clearData={clearData} logout={logout} getPost={getPost} getProject={getProject}/>}/>
      <Route path='/projects' element={<Post buttonOnClick={saveProject} button='Save changes' postHeader="Projects" data={pData} />} />
      <Route path='/add' element={<Menu/>} />
      <Route path='/add/project' element={<Post buttonOnClick={newProject} button='Post' postHeader='Add new Project' data={{text:'new Text' , filepath:'new Filepath' , link : 'new Link' , postHeader:'new Header', textGerman:"Text auf deutsch"}} />} />
      <Route path='/add/post' element={<Post buttonOnClick={newPost} button='Post' postHeader='Add new Post' data={{text:'new Text' , date:'new Date' , postHeader:'new Header',textGerman:"Text auf deutsch"}} />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
