import React, { useEffect,useState } from "react";
import "./login-stylesheet.css";
import axios from "axios";

function Login (props) {
    let [username,setUsername] = useState("");
    let [password,setPassword] = useState("");
    let apiUrl = process.env.REACT_APP_API_URL;

    function callLogin(){
        axios.post(apiUrl+"/login",{username:username,password:password}).then((response)=>{
          if(response.data.message === "success"){
            props.successLogin();
        }else{
            props.errorLogin();
        }
        });
      }
      useEffect(()=>{
        let listener = event => {
            if(event.code ==="Enter" || event.code ==="NumpadEnter"){
                callLogin();
            }
        };
        document.addEventListener("keydown", listener);
        return()=>{
            document.removeEventListener("keydown", listener);
        }
                     // eslint-disable-next-line
    },[username,password]);
return(
<>
<div className="Login-Container">
<section>
    <div className="sigin">
        <div className="content">
            <h2 className="signin-header">Sign In</h2>
            <div className="form">
                <div className="inputBox">
                    <input type="text" placeholder="Username" onChange={(e)=>setUsername(e.target.value)} required />
                </div>
                <div className="inputBox">
                    <input type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} required />
                </div>
                <div className="inputBox">
                    <input  type="submit" value="Login" onClick={callLogin}/>
                    <p style={{color:"red"}}>{props.error}</p>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</>
)
}

export default Login;
