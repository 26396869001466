import React, { useEffect, useState } from "react";
import "./post-stylesheet.css";
import Navbar from "../navbar/navbar";

function Post (props){
console.log(props);
    let [text,setText] = useState("");
    let [filepath,setFilepath] = useState("");
    let [link,setLink] = useState("");
    let [header,setHeader] = useState("");
    let [date,setDate] = useState("")
    let [loader,setLoader] = useState(true);
    let [loaderMessage,setLoaderMessage] = useState("");
    let [textGerman,setTextGerman] =useState("");
             // eslint-disable-next-line
     let newData = {
        text:text,
        date:date,
        filepath:filepath,
        link:link,
        header:header,
        id:props.data.id,
        textGerman:textGerman
     }
    let [dateHidden,setDateHidden] = useState(false);
    let [filepathHidden,setFilepathHidden] = useState(false);
    let [linkHidden,setLinkHidden] = useState(false);

useEffect(()=>{
    if (props.data) {
        setText(props.data.text || "");
        setFilepath(props.data.filepath || 0);
        setLink(props.data.link || 0);
        setHeader(props.data.postHeader || "");
        setDate(props.data.date || 0);
        setTextGerman(props.data.textGerman || "");
    }
                 // eslint-disable-next-line
},[props.data]);

useEffect(()=>{
        if(!props.data.date){
            setDateHidden(true);
        }else{
            setDateHidden(false);
        }
        if(!props.data.filepath){
            setFilepathHidden(true);
        }else{
            setFilepathHidden(false);
        }
        if(!props.data.link){
            setLinkHidden(true);
        }else{
            setLinkHidden(false);
        }
                 // eslint-disable-next-line
},[props.data]);

function callLoader(){
    setLoader(false)
    setTimeout(()=>{
        setLoader(true)
        setLoaderMessage("success");
        setTimeout(()=>{setLoaderMessage("")},1000*5);
    },1000*3);
}
return(
<>
<section>
<div className="Post-Container">
        <div className="Post-Form">
                <div className="Post-Header">
                    <h2 className="Post-Header">{props.postHeader}
                    <div class="col-3">
                        <div hidden={loader} class="snippet" data-title="dot-spin">
                            <div class="stage">
                                <div class="dot-spin">
                                </div>
                            </div>
                        </div>
                    </div>
                    </h2>
                </div>
                <div className="Post-Navbar">
                    <Navbar backgroundColor={'#333'} clearData={props.clearData} />
                </div>
                <input id="header"  className="Post-SubHeader" type="text" value={header} onChange={((e)=>{setHeader(e.target.value)})} />
                <textarea id="text" className="Post-Text-1" value={text} onChange={((e)=>{setText(e.target.value)})} />
                <textarea id="text" className="Post-Text-2" value={textGerman} onChange={((e)=>{setTextGerman(e.target.value)})} ></textarea>
                <input id="date"  hidden={dateHidden} className="Post-Date" type="text" value={date} onChange={((e)=>{setDate(e.target.value)})} />
                <input id="filepath" hidden={filepathHidden} className="Post-Filepath" type="text" value={filepath} onChange={((e)=>{setFilepath(e.target.value)})} />
                <input id="link" hidden={linkHidden} className="Post-Link" type="text" value={link} onChange={((e)=>{setLink(e.target.value)})} />
                <button 
                onClick={()=>{
                    props.buttonOnClick(newData);
                    callLoader();    
                }} 
                className="Post-Button">{loaderMessage ? loaderMessage : props.button}</button>
        </div>
    </div>
</section>
</>
)
}

export default Post;