import React, { useState } from "react";
import Add from "../../assets/img/add.png";
import Logout from "../../assets/img/logout.png";
import { Link } from "react-router-dom";
import "./navbar-stylesheet.css";
import AddGreen from "../../assets/img/add-green.png";
import LogoutGreen from "../../assets/img/logout-green.png";


//<a target="_blank" href="https://icons8.com/icon/n9aysyUpRtGD/logout">Logout</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
function Navbar(props){

    let [addImg,setAddImg] = useState(Add);
    let [logoutImg,setLogoutImg] = useState(Logout);
return(
<>
    <div style={{background : props.backgroundColor}} className="Navbar-Container">
        <ul className="Navbar">
            <Link to="/home"><button onClick={props.clearData} id="Home" className="Navbar-Button">Home</button></Link>
            <Link to="/add" ><button className="Navbar-Button"><img id="Add"onMouseOver={()=>{setAddImg(AddGreen)}  } src={addImg} onMouseLeave={() => {setAddImg(Add)} }  alt="Add-Button"/></button></Link>
            <Link to='/'><button onClick={props.logout} className="Navbar-Button"><img id="Logout" onMouseOver={()=>{setLogoutImg(LogoutGreen)}} onMouseLeave={()=>{setLogoutImg(Logout)}}  src={logoutImg} alt="Logout-Button" /></button></Link>
        </ul>
    </div>
</>
);
}
export default Navbar;