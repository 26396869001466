import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import "./editor-stylesheet.css"
import Navbar from "../navbar/navbar";
import axios from "axios";
import DeletImg from "../../assets/img/delete.png";
import EditImg from "../../assets/img/edit.png";
import DeletImgGreen from "../../assets/img/delete-green.png";
import EditImgGreen from "../../assets/img/edit-green.png";



function Editor(props){
    
    let apiUrl = process.env.REACT_APP_API_URL;
    let [projects,setProjects] = useState([]);
    let [posts,setPosts] = useState([]);
    let [isHoveredEdit, setIsHoveredEdit] = useState({});
    let [isHoveredDelete, setIsHoveredDelete] = useState({});

    async function getPosts(){
        await axios.get(apiUrl+"/projects",{responseType:"json"}).then((response)=>{
            setProjects(response.data.data)});
        await axios.get(apiUrl+"/blogposts",{responseType:"json"}).then((response)=>{
            setPosts(response.data)
        });
    }
    useEffect(()=>{
        getPosts();
        // eslint-disable-next-line
    },[]);
    let projectComponents = projects.map((data, i) => {
        return (
            <div key={i} className="Item-Container">
                <h2 className="Item-Header">{data.project_name}</h2>
                <a className="Item-Sub-Header" href={data.project_link}>Link</a>
                <div id="Button-Container" className="Item-Button-One">
                    <Link to="/projects">
                        <button
                            onClick={() => { props.getProject(data.id) }}
                            onMouseEnter={() => setIsHoveredEdit(prevState => ({ ...prevState, ['projects'+i]: true }))}
                            onMouseLeave={() => setIsHoveredEdit(prevState => ({ ...prevState, ['projects'+i]: false }))}
                            className="Item-Button-one"
                        >
                            <img
                                className="Button-Image"
                                src={isHoveredEdit['projects'+i] ? EditImgGreen : EditImg}
                                alt="edit-img"
                            />
                        </button>
                    </Link>
                </div>
                <div id="Button-Container" className="Item-Button-Two">
                    <button
                        onClick={() => props.deleteProject(data.id)}
                        onMouseEnter={() => setIsHoveredDelete(prevState => ({ ...prevState, ['projects'+i]: true }))}
                        onMouseLeave={() => setIsHoveredDelete(prevState => ({ ...prevState, ['projects'+i]: false }))}
                        value={data.project_name}
                        className="Item-Button-two"
                    >
                        <img
                            className="Button-Image"
                            src={isHoveredDelete['projects'+i] ? DeletImgGreen : DeletImg}
                            alt="delete-img"
                        />
                    </button>
                </div>
            </div>
        )
    });
    let postsComponents = posts.map((data,i)=>{
        let formattedDate = data.blog_date;
        return(
        <div key={i} className="Item-Container">
            <h2 className="Item-Header">{data.header}</h2>
            <p className="Item-Sub-Header">{formattedDate}</p>
            <div id="Button-Container" className="Item-Button-One">
            <Link to="/post">
                        <button
                            onClick={() => { props.getPost(data.id) }}
                            onMouseEnter={() => setIsHoveredEdit(prevState => ({ ...prevState, ['post'+i]: true }))}
                            onMouseLeave={() => setIsHoveredEdit(prevState => ({ ...prevState, ['post'+i]: false }))}
                            className="Item-Button-one"
                        >
                            <img
                                className="Button-Image"
                                src={isHoveredEdit['post'+i] ? EditImgGreen : EditImg}
                                alt="edit-img"
                            />
                        </button>
                    </Link>
            </div>
            <div id="Button-Container" className="Item-Button-Two">
            <button
                        onClick={() => props.deleteProject(data.id)}
                        onMouseEnter={() => setIsHoveredDelete(prevState => ({ ...prevState, [data.id]: true }))}
                        onMouseLeave={() => setIsHoveredDelete(prevState => ({ ...prevState, [data.id]: false }))}
                        value={data.project_name}
                        className="Item-Button-two"
                    >
                        <img
                            className="Button-Image"
                            src={isHoveredDelete[data.id] ? DeletImgGreen : DeletImg}
                            alt="delete-img"
                        />
                    </button>
            </div>
        </div>
        )
    });

return (<>
    <div className="Home-Container">
        <div className="Home-Navbar">
            <Navbar logout={props.logout}/>
        </div>
        <div className="Content-Container">
        <div className="Projects-Container">
        <h1 className="Header">Projects</h1>
        {projectComponents}
        </div>
        <div className="Posts-Container">
        <h1 className="Header">Posts</h1>
        {postsComponents}
        </div>
        </div>
    </div>
</>)
}

export default Editor;